// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const generateToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_vapidKey,
    });
    console.log("currentToken: ", currentToken);
    return currentToken;
  } catch (error) {
    console.log("Something went wrong in firebase");
  }
};

const generatePermission = async () => {
  try {
    const storedPermission = localStorage.getItem("notificationPermission");

    if (storedPermission === "granted") {
      const storedToken = localStorage.getItem("fcmToken");
      if (storedToken) {
        return storedToken;
      } else {
        const newToken = await generateToken();
        return newToken;
      }
    }

    const permission = await Notification.requestPermission();
    localStorage.setItem("notificationPermission", permission);

    if (permission === "granted") {
      const token = await generateToken();
      return token;
    } else {
      console.error("Notification permission denied.");
    }
  } catch (error) {
    console.error("An error occurred while fetching the token.", error);
  }
};

export { messaging, getToken, generatePermission, generateToken, onMessage };
