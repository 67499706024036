// const baseUrl = `${process.env.REACT_APP_APP_API}/api`;
export const socketUrl = `${process.env.REACT_APP_APP_API}`;

// Production
const baseUrl = `${process.env.REACT_APP_APP_API}/api`;

// Staging

// export const WEBSOCKET_URL = "http://localhost:8080";
export const WEBSOCKET_URL = "https://staging-server-new.ideaverse.ai";

// const baseUrl = "http://localhost:8080/api";
// const baseUrl = "https://staging-server-new.ideaverse.ai/api";

export default baseUrl;
